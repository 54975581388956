import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { computeColor } from '../utils/colors';

function Theme() {
  const { resolvedTheme, setTheme } = useTheme();

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const isDark = resolvedTheme === 'dark';

  function setAndGenerateColors(theme: string) {
    setTheme(theme);
    computeColor(theme);
  }

  return !isSSR ? (
    <div
      className={`mt-4 w-fit relative grid grid-cols-2 rounded-full border border-outline-variant bg-secondary-container text-on-secondary-container p-1`}
    >
      <button
        className={`h-8 w-8 rounded-full flex flex-row items-center justify-center hover:opacity-100 transition-all ${
          isDark ? 'bg-black/10 dark:bg-white/10' : 'opacity-60'
        }`}
        onClick={() => setAndGenerateColors('dark')}
      >
        <MoonIcon className="flex-shrink-0 h-4 w-4 mt-[2px]" />
      </button>
      <button
        className={`h-8 w-8 rounded-full flex flex-row items-center justify-center hover:opacity-100 transition-all ${
          !isDark ? 'bg-black/10 dark:bg-white/10' : 'opacity-60'
        }`}
        onClick={() => setAndGenerateColors('light')}
      >
        <SunIcon className="flex-shrink-0 h-6 w-6" />
      </button>
    </div>
  ) : null;
}

export default Theme;
