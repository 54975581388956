
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.scss';
import '../styles/nprogress.css';

import { useRouter } from 'next/dist/client/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';

import Layout from '../components/Layout';
import { UserContext } from '../lib/context';
import { useUserData } from '../lib/hooks';

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

const __Page_Next_Translate__ = function MyApp({ Component, pageProps, err }) {
  const userData = useUserData();

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());
  }, [router]);

  return (
    <UserContext.Provider value={userData}>
      <Layout>
        <Component {...pageProps} err={err} />
      </Layout>
    </UserContext.Provider>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  