import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useContext } from 'react';

import classNames from 'classnames';
import { UserContext } from '../lib/context';
import { NavAvatar } from './NavAvatar';

export default function Nav() {
  const { user, clientData } = useContext(UserContext);

  const { t } = useTranslation('common');
  return (
    <nav
      className={`z-50 transition-all duration-300 fixed top-0 inset-x-0 h-14 bg-gray-100/50 dark:bg-gray-800/50 border-b border-outline-variant backdrop-blur`}
    >
      <div className="h-full max-w-[90rem] mx-auto px-8 flex flex-row items-center">
        <Link
          href="/"
          className={`transition-all duration-300 ease-in-out cursor-pointer font-bringmos font-bold text-xl py-2 ${'text-black dark:text-white'}`}
        >
          BRINGMOS
        </Link>
        <span className="flex-grow"></span>
        <Link
          href="https://bringmos.io"
          className={classNames(
            'block rounded-md px-3 py-2 font-medium',
            'text-teal-600 hover:text-teal-500 hover:underline underline-offset-8 decoration-teal-700',
          )}
          target="_blank"
        >
          Business
        </Link>
        <NavAvatar />
      </div>
    </nav>
  );
}
