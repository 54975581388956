'use client';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import { signOut } from 'firebase/auth';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { Fragment, useContext } from 'react';
import { UserContext } from '../lib/context';
import { auth } from '../lib/firebase';
import { User } from '../types/src';
import { Button, ButtonVariants } from './Button';

export const NavAvatar = () => {
  const { user, clientData } = useContext(UserContext);

  const router = useRouter();

  const { t } = useTranslation('common');

  return user && clientData ? (
    <Popover className="relative z-40">
      {({ open }) => (
        <>
          <PopoverButton
            className={`
                ${open ? '' : 'text-opacity-90'}
             group ml-4 inline-flex items-center rounded-md py-2 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <AvatarCircle user={user} clientData={clientData} />
          </PopoverButton>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute right-0 z-30 mt-3 block w-96 transform pl-8">
              <div className="relative overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4 bg-surface-container text-on-surface-container">
                  <div className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out">
                    <div className="flex flex-col items-center">
                      <div className="relative mb-4 h-20 w-20">
                        {user.photoURL ? (
                          <Image
                            alt="user pic"
                            src={user.photoURL}
                            width={80}
                            height={80}
                            className="h-full w-full rounded-full shadow"
                          />
                        ) : (
                          <UserIcon className="w-20 h-20" />
                        )}
                      </div>
                      <span className="">{clientData.client_name}</span>
                      <span className="">{clientData.client_email}</span>
                      <span className="italic text-xs">{t('guest_account')}</span>

                      <Link href="/profile" className="mx-auto my-4">
                        <Button>{t('go_to_profile')}</Button>
                      </Link>

                      <div className="flex w-full flex-col items-center border-t border-outline-variant">
                        <Button
                          variant={ButtonVariants.Secondary}
                          onClick={() =>
                            signOut(auth).then(() => {
                              router.push(`/profile`);
                            })
                          }
                          className="mx-auto mt-4 self-end rounded-md bg-white px-4 py-2 text-sm text-primary shadow outline-none focus:outline-none dark:bg-gray-700 dark:text-bringmos-400"
                        >
                          {t('logout')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  ) : (
    <Link className="ml-4" href={`/profile`}>
      <Button variant={ButtonVariants.Secondary}>{t('nav.signin')}</Button>
    </Link>
  );
};

export function AvatarCircle({ user, className }: { user: any; clientData: User; className?: string }) {
  return (
    <div
      className={classnames(
        'flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-200 ring ring-gray-300 ring-opacity-70 transition-all hover:ring-gray-400 hover:ring-opacity-100 dark:bg-gray-800 dark:ring dark:ring-gray-700 hover:dark:ring-gray-500',
        className,
      )}
    >
      <div>
        {user.photoURL ? (
          <Image alt="user pic" src={user.photoURL} width={40} height={40} className="h-full w-full rounded-full shadow" />
        ) : (
          <UserIcon className="w-5 h-5" />
        )}
      </div>
    </div>
  );
}
